import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import ChatContainer from '@components/ChatContainer';
import { ToastContainer } from 'react-toastify';
import { getFileSrc } from '@utils/files';
import {
  setIsEmbeddedModeEnabled,
  setIsMobileModeEnabled,
  updateSettings,
} from '@states/slices/settingsSlice';
import {
  setDisplayChatbot,
  setCurrentUser,
  setIsValidUser,
  setOpenChatWindow,
  setWaitingForBotReply,
  setIsInputDisabled,
  setSavedCookies,
  setIframeParentURL,
} from '@states/slices/chatbotSlice';
import { useEffect, useState } from 'react';
import usersService from '@services/usersService';
import useCurrentSkin from '@hooks/useCurrentSkin';
import ChatPreview from '@components/ChatPreview';
import '@styles/pages/Home.scss';
import eventsService from '@services/eventsService';
import validationService from '@services/validationService';
import { useIdleEventTimeout } from '@hooks/useIdleEventTimeout';
import useWebSockets from '@hooks/useWebSockets';
import { getLanguageFromCode } from '@/utils/utils';

function Home() {
  const {
    botBackground,
    abTestPercentage,
    productSelection,
    isDemoModeEnabled,
    isEmbeddedModeEnabled,
    isMobileModeEnabled,
    isFullScreenModeEnabled,
    isPasswordEnabled,
    isErrorDisplayEnabled,
    isAuthedUsersOnlyEnabled,
    isMobileAgentEnabled,
    isConfigurationLoaded,
    isInitEventEnabled,
    isDefaultOpenEnabled,
    isDefaultOpenMobileEnabled,
    isPreviewEnabled,
  } = useSelector((state: RootState) => state.settings);
  const { iframeParentUrl } = useSelector((state: RootState) => state.chatbot);
  const { openChatWindow, displayChatbot, currentUser } = useSelector(
    (state: RootState) => state.chatbot
  );
  const { currentSkinName } = useSelector((state: RootState) => state.skins);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [initEventData, setInitEventData] = useState<any>(null);
  const currentSkin = useCurrentSkin();
  const OpenButton = currentSkin.components.openButton;
  const dispatch = useDispatch();

  const { checkIdleTimeout } = useIdleEventTimeout();

  useWebSockets();

  async function validateIframeOrigin(parentUrl: string): Promise<any> {
    const parentDomain = new URL(parentUrl).hostname;
    const directDomain = new URL(window.location.origin).hostname;
    console.info(
      'Validating iframe origin:',
      parentDomain,
      directDomain,
      parentUrl
    );
    const { isOriginValid, botVariant } =
      await validationService.validateIframeOrigin(
        parentDomain,
        directDomain,
        parentUrl
      );
    return { isOriginValid, botVariant };
  }

  function isChatbotVisible(
    externalUserId: string,
    isAbEnabled: boolean
  ): boolean {
    if (isAuthedUsersOnlyEnabled && !externalUserId) {
      console.info(
        'Chatbot is only open to authenticated users. Hiding chatbot'
      );
      return false;
    }
    const chatbotVisible = isAbEnabled
      ? Math.random() * 100 < abTestPercentage
      : true;
    console.info('Chatbot visibility status is:', chatbotVisible);
    return chatbotVisible;
  }

  async function setNewUser(
    isAbEnabled: boolean,
    externalUserId: string = '',
    userFirstName: string = '',
    userLastName: string = ''
  ): Promise<void> {
    const newUserId = uuidv4();
    console.info(
      `Setting new user with ID: ${newUserId} and external ID: ${
        externalUserId ? externalUserId : 'N/A'
      } with user first name: ${userFirstName} and last name: ${userLastName} 
      and AB test enabled: ${isAbEnabled} and open only to authenticated: ${isAuthedUsersOnlyEnabled}`
    );
    const chatbotVisible = isChatbotVisible(externalUserId, isAbEnabled);
    localStorage.setItem('currentUser', newUserId);
    dispatch(setCurrentUser(newUserId));
    await usersService.addNewUser(
      newUserId,
      externalUserId,
      userFirstName,
      userLastName,
      chatbotVisible,
      initEventData
    );
    dispatch(setDisplayChatbot(chatbotVisible));
  }

  async function initiateUserAndConversation(
    externalUserId: string = '',
    userFirstName: string = '',
    userLastName: string = '',
    isAbEnabled = false
  ): Promise<void> {
    const savedUserId = localStorage.getItem('currentUser') || '';
    console.info(
      `Initiating user and conversation with external user ID: ${
        externalUserId ? externalUserId : 'N/A'
      } and saved user ID: ${savedUserId}`
    );
    if (!savedUserId) {
      console.info('No user ID found. Setting new user.');
      await setNewUser(
        isAbEnabled,
        externalUserId,
        userFirstName,
        userLastName
      );
      return;
    }
    const { userFound, userData } = await usersService.getSavedUserInfo(
      savedUserId
    );
    if (!userFound) {
      console.info('Saved user not found. Setting new user:', externalUserId);
      await setNewUser(
        isAbEnabled,
        externalUserId,
        userFirstName,
        userLastName
      );
      return;
    }
    console.info(
      `User found: ${savedUserId}. Chatbot visible: ${userData.chatbot_visible}. External ID: ${userData.external_id}`
    );
    dispatch(setCurrentUser(savedUserId));
    if (userData.external_id !== externalUserId) {
      console.info(
        `External user ID updated. Changing from ${userData.external_id} to ${externalUserId}`
      );
      const chatbotVisible = isChatbotVisible(externalUserId, isAbEnabled);
      await usersService.updateUserInfo(
        savedUserId,
        externalUserId,
        userFirstName,
        userLastName,
        chatbotVisible
      );
      dispatch(setDisplayChatbot(chatbotVisible));
      if (externalUserId && externalUserId !== '') {
        setIsLoggedInUser(true);
      }
    }
    if (!isPasswordEnabled) dispatch(setIsValidUser(true));
    dispatch(setDisplayChatbot(userData.chatbot_visible));
  }

  async function startStandaloneChatbot(
    externalUserId: string,
    userFirstName: string,
    userLastName: string
  ) {
    if (isFullScreenModeEnabled) {
      console.info('Starting chatbot in fullscreen mode');
      const { isOriginValid, botVariant } = await validateIframeOrigin(
        window.location.origin
      );
      if (!isOriginValid) {
        dispatch(setDisplayChatbot(false));
        return;
      }
      if (botVariant) {
        dispatch(updateSettings({ variantSelection: botVariant }));
      }
      dispatch(setOpenChatWindow('OPEN'));
    }
    initiateUserAndConversation(externalUserId, userFirstName, userLastName);
  }

  function getIsMobile(value: string | null) {
    if (!value) return window.innerWidth <= 768;
    return value === 'true';
  }

  function parseURLParameters() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    console.info(
      urlParams.get('cookies')
        ? `Cookies found: ${urlParams.get('cookies')}`
        : 'no cookies'
    );
    return {
      originUrl: urlParams.get('originUrl') || '',
      isMobile: getIsMobile(urlParams.get('isMobile')),
      externalUserId: urlParams.get('userId') || urlParams.get('userID') || '',
      userFirstName: urlParams.get('firstName') || '',
      userLastName: urlParams.get('lastName') || '',
      userLanguage: urlParams.get('language') || '',
      cookies: urlParams.get('cookies') || '',
      isCustomIframeEnabled: urlParams.get('isCustomIframeEnabled') === 'true',
    };
  }

  useEffect(() => {
    async function handleLoginEvent() {
      console.info(`Handling login event for user ID: ${currentUser}`);
      dispatch(setWaitingForBotReply(true));
      dispatch(setIsInputDisabled(true));
      // TODO: Do we want to do something with the return ai_message for a login event?
      await eventsService.handleLoginEvent(currentUser, productSelection);
      dispatch(setWaitingForBotReply(false));
      dispatch(setIsInputDisabled(false));
    }

    if (!isLoggedInUser) return;
    handleLoginEvent();
  }, [isLoggedInUser]);

  useEffect(() => {
    async function fetchData() {
      // Verbose logging for debugging purposes - will be removed
      if (isInitEventEnabled) {
        console.info('Init event is enabled. Checking for init event data...');
        if (!initEventData) {
          console.info('No init event data received yet...');
          return;
        } else {
          console.info('Init event data received:', initEventData);
        }
      } else {
        console.info('Init event is disabled. Continuing...');
      }
      const {
        originUrl,
        isMobile,
        externalUserId,
        userFirstName,
        userLastName,
        userLanguage,
        isCustomIframeEnabled,
        cookies,
      } = parseURLParameters();
      console.info(
        `Parsed URL parameters: originUrl: ${originUrl}, isMobile: ${isMobile}, externalUserId: ${externalUserId}, isCustomIframeEnabled: ${isCustomIframeEnabled}, userFirstName: ${userFirstName}, userLastName: ${userLastName}, userLanguage: ${userLanguage}`
      );
      if (userLanguage) {
        const languageSelection = getLanguageFromCode(userLanguage);
        dispatch(updateSettings({ languageSelection }));
      }

      const isDefaultOpen =
        openChatWindow === 'OPEN' ||
        (isMobile ? isDefaultOpenMobileEnabled : isDefaultOpenEnabled);
      const state = isDefaultOpen
        ? 'OPEN'
        : isPreviewEnabled
        ? 'PREVIEW'
        : 'CLOSED';
      dispatch(setOpenChatWindow(state));

      if (originUrl == '' || originUrl === window.location.origin) {
        dispatch(setIsEmbeddedModeEnabled(false));
        startStandaloneChatbot(externalUserId, userFirstName, userLastName);
      } else {
        console.info('Starting chatbot in embedded mode:', originUrl);
        if (isMobile && !isMobileAgentEnabled) {
          console.info('Mobile mode is disabled. Hiding chatbot');
          dispatch(setDisplayChatbot(false));
          return;
        }
        dispatch(setIsEmbeddedModeEnabled(true));
        dispatch(
          updateSettings({
            isFullScreenModeEnabled: isCustomIframeEnabled,
            isRestartEnabled: false,
            isSettingsEnabled: false,
          })
        );
        if (isCustomIframeEnabled) dispatch(setOpenChatWindow('OPEN'));
        try {
          const { isOriginValid, botVariant } = await validateIframeOrigin(
            originUrl
          );
          if (!isOriginValid) {
            dispatch(setDisplayChatbot(false));
            return;
          }
          dispatch(setIframeParentURL(originUrl));
          console.info(
            `Valid event origin: ${originUrl}. Starting chatbot in embedded mode ${
              botVariant ? `with variant ${botVariant}` : ''
            }`
          );
          dispatch(setIsMobileModeEnabled(isMobile));
          if (botVariant) {
            dispatch(updateSettings({ variantSelection: botVariant }));
          }
          if (cookies) {
            console.info('Saving found cookies...');
            const updatedCookies = `${cookies}; theurl=${originUrl}`;
            dispatch(setSavedCookies(updatedCookies));
          }
          await initiateUserAndConversation(
            externalUserId,
            userFirstName,
            userLastName,
            true
          );
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    }
    if (isConfigurationLoaded) fetchData();
  }, [currentUser, isConfigurationLoaded, initEventData]);

  useEffect(() => {
    if (!iframeParentUrl || iframeParentUrl === '') return;
    if (currentUser && currentUser !== '') {
      window.parent.postMessage(
        {
          type: 'SESSION_ID',
          sessionId: currentUser,
        },
        iframeParentUrl
      );
    }
  }, [currentUser]);

  useEffect(() => {
    if (!iframeParentUrl || iframeParentUrl === '') return;

    console.info(
      'Chatbot window state changed:',
      !displayChatbot ? 'HIDDEN' : openChatWindow
    );

    const chatbotState = !displayChatbot
      ? 'hidden'
      : openChatWindow.toLowerCase();

    window.parent.postMessage(
      {
        type: 'CHATBOT_STATE',
        state: chatbotState,
        skin: currentSkinName,
      },
      iframeParentUrl
    );
  }, [openChatWindow, iframeParentUrl, displayChatbot]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      dispatch(setIsMobileModeEnabled(true));
    }
  }, []);

  useEffect(() => {
    if (currentUser) {
      console.info('Checking idle timeout...');
      checkIdleTimeout();
    }
  }, [currentUser]);

  // TODO: InsaitResponseFromServer is for debugging purposes only in Leumi flow. We want to check if the agent can receive postMessage events from the parent window while in webview. Please remove this code block after testing.
  // OpenInsaitFirstEvent will stay in Home.tsx to handle any init events from the parent window
  useEffect(() => {
    function messageHandler(event: MessageEvent) {
      console.info('Received message from parent window:', event);
      const eventData =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

      if (eventData.type === 'InsaitResponseFromServer') {
        console.info('Received response from Leumi service:', eventData);
      } else if (eventData.type === 'OpenInsaitFirstEvent') {
        console.info('Received init event from Leumi service:', eventData);
        setInitEventData(eventData.data);
      }
    }

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
      console.info('Removing event listener');
    };
  }, []);

  return (
    <>
      {displayChatbot ? (
        <div
          className={`main ${openChatWindow.toLowerCase()} ${
            isEmbeddedModeEnabled ? 'embedded' : ''
          } ${isMobileModeEnabled ? 'mobile' : ''} ${currentSkinName}`}
        >
          {!isEmbeddedModeEnabled && isDemoModeEnabled ? (
            <img src={getFileSrc(botBackground)} className='user-background' />
          ) : null}
          {openChatWindow === 'OPEN' && <ChatContainer />}
          {openChatWindow === 'PREVIEW' && <ChatPreview />}
          {!isFullScreenModeEnabled ? <OpenButton /> : null}
          {!isEmbeddedModeEnabled && isErrorDisplayEnabled ? (
            <ToastContainer
              autoClose={3000}
              closeOnClick
              pauseOnFocusLoss={false}
              theme='light'
            />
          ) : null}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Home;

import { ConfigurationData } from '@/utils/types';
import { CONFIGURATIONS_SERVICE_URL } from '@utils/constants';
import axios from 'axios';

const companyService = {
  async getCompanyConfig(companyName: string) {
    console.info(
      `Getting company config for ${companyName} using ${CONFIGURATIONS_SERVICE_URL}`
    );
    const response = await axios.get(
      `${CONFIGURATIONS_SERVICE_URL}/?name=${companyName}&product=bot&section=ui`
    );
    const { data } = response.data;
    // TODO: Let's have fallbacks for everything for better documentation of the default state of the bot
    const configData: ConfigurationData = {
      abTestPercentage: data.ab_test_percentage,
      languageSelection: data.language,
      productSelection: data.product,
      variantSelection: data.default_variant,
      skinName: data.skin_name,
      isStreamingEnabled: data.streaming_enabled ?? true,
      botImage: data.images.bot_image,
      favicon: data.images.favicon,
      botBackground: data.images.bot_background,
      footerLogo: data.images.footer_logo,
      color1: data.palette.color1,
      color2: data.palette.color2,
      botNameColor: data.palette.bot_name_color,
      botMessageColor: data.palette.bot_message_color,
      userMessageColor: data.palette.user_message_color,
      isDefaultOpenEnabled:
        data.extra_configurations.default_open_enabled ?? false,
      isDefaultOpenMobileEnabled:
        data.extra_configurations.default_open_mobile_enabled ?? false,
      isDemoModeEnabled: data.extra_configurations.demo_mode_enabled,
      isErrorDisplayEnabled: data.extra_configurations.error_display_enabled,
      isFreemiumModeEnabled: data.extra_configurations.freemium_mode_enabled,
      isFullScreenModeEnabled:
        data.extra_configurations.fullscreen_mode_enabled,
      isPasswordEnabled: data.extra_configurations.password_enabled,
      isPreviewEnabled: data.extra_configurations.preview_enabled ?? true,
      isRestartEnabled: data.extra_configurations.restart_enabled,
      isSettingsEnabled: data.extra_configurations.settings_enabled,
      isTransferEnabled: data.extra_configurations.transfer_enabled ?? false,
      isAddNewConversationEnabled:
        data.extra_configurations.add_new_conversation_enabled ?? false,
      isAuthedUsersOnlyEnabled:
        data.extra_configurations.authed_users_only_enabled ?? false,
      isLikeButtonsEnabled:
        data.extra_configurations.like_buttons_enabled ?? false,
      isAutoFlowEnabled: data.extra_configurations.auto_flow_enabled ?? false,
      isIdleEventEnabled: data.extra_configurations.idle_event_enabled ?? false,
      idleEventTimeoutInMinutes:
        data.extra_configurations.idle_event_timeout_in_minutes ?? 10,
      isOnlineStatusEnabled:
        data.extra_configurations.online_status_enabled ?? true,
      isFileSendEnabled: data.extra_configurations.file_send_enabled ?? true,
      isEmojiSendEnabled: data.extra_configurations.emoji_send_enabled ?? true,
      isLikeFeedbackEnabled:
        data.extra_configurations.like_feedback_enabled ?? false,
      isFeedbackSummaryEnabled:
        data.extra_configurations.feedback_summary_enabled ?? false,
      isMobileAgentEnabled:
        data.extra_configurations.mobile_agent_enabled ?? true,
      isFeedbackMessageEnabled:
        data.extra_configurations.feedback_message_enabled ?? true,
      isFeedbackRatingEnabled:
        data.extra_configurations.feedback_rating_enabled ?? true,
      isInitEventEnabled: data.extra_configurations.init_event_enabled ?? false,

      // TODO: have another sub-object for textual configurations: we can put all the text related configurations here, for example: pageTitle, metaDescription, buttonText, botName, etc. For backward compatibility, we can keep the current structure as well as a fallback. Keeping an AND condition for now for data.texts for companies that have not yet migrated to the new structure.
      botName: (data.texts && data.texts.bot_name) || data.bot_name,
      buttonText: (data.texts && data.texts.button_text) || data.button_text,
      pageTitle: (data.texts && data.texts.page_title) || data.page_title,
      metaDescription:
        (data.texts && data.texts.meta_description) ||
        data.meta_description ||
        null,
      inputFieldPlaceholder: data.texts && data.texts.input_field_placeholder,

      //external integrations:
      fullStoryOrgId:
        (data.external_integrations &&
          data.external_integrations.full_story_org_id) ||
        '',
    };
    return configData;
  },
};

export default companyService;

import { init } from '@fullstory/browser';

export function initFullStory(fullStoryOrgId: string) {
  try {
    if (typeof window === 'undefined') {
      throw new Error(
        'initFullStory can only be used in a browser environment.'
      );
    }
    window['_fs_run_in_iframe'] = true;
    if (!fullStoryOrgId || typeof fullStoryOrgId !== 'string') {
      throw new Error('Invalid FullStory Org ID.');
    }
    init({ orgId: fullStoryOrgId });
  } catch (error) {
    console.error('Error initializing FullStory:', error);
  }
}

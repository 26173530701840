import { BACKEND_URL } from '@utils/constants';
import apiClient from '@utils/axios';

const utilsService = {
  async uploadFile(FormData: FormData) {
    try {
      const response = await apiClient.post(
        `${BACKEND_URL}/storage/`,
        FormData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async fetchFile(file_url: string) {
    try {
      const response = await apiClient.get(`${BACKEND_URL}/storage/`, {
        params: { file_url },
        responseType: 'blob',
      });
      const pdfBlobUrl = URL.createObjectURL(response.data);
      return pdfBlobUrl;
    } catch (error) {
      throw error;
    }
  },
};
export default utilsService;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import {
  resetLatestResponse,
  setChatHistory,
  setFinalBotReply,
  setIsInputDisabled,
  setLatestResponseId,
} from '@states/slices/chatbotSlice';
import { formatText } from '@utils/utils';
import { useIdleEventTimeout } from '@/hooks/useIdleEventTimeout';
import { useAutoScroll } from '@/hooks/useAutoScroll';

function LatestResponse({
  chatContainerRef,
}: {
  chatContainerRef: React.RefObject<HTMLDivElement>;
}) {
  const [displayedText, setDisplayedText] = useState<string>('');
  const dispatch = useDispatch();
  const { chatHistory, latestResponse, isStreamFinished, finalBotReply } =
    useSelector((state: RootState) => state.chatbot);
  const { isIdleEventEnabled } = useSelector(
    (state: RootState) => state.settings
  );

  const { handleIdleTimeout } = useIdleEventTimeout();
  const scrollToBottom = useAutoScroll(chatContainerRef);

  useEffect(() => {
    const newText = latestResponse.text;

    if (
      newText === '' ||
      displayedText.endsWith(newText) ||
      (isStreamFinished && newText === '')
    ) {
      return;
    }

    const words = newText.split(' ');
    const startIndex =
      displayedText.length > 0 ? displayedText.trim().split(' ').length : 0;
    let i = startIndex;

    const typingInterval = setInterval(() => {
      if (i < words.length) {
        const newDisplayText = words.slice(0, i + 1).join(' ');
        setDisplayedText(newDisplayText);
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, [latestResponse.text]);

  async function handleSetNewChatHistory() {
    if (finalBotReply === null) return;

    const history = [...chatHistory, finalBotReply];
    dispatch(setLatestResponseId(finalBotReply.id));
    dispatch(setChatHistory(history));
  }

  useEffect(() => {
    if (
      isStreamFinished &&
      latestResponse.text !== '' &&
      isDisplayedTextFinished() &&
      finalBotReply !== null
    ) {
      handleSetNewChatHistory();
      dispatch(setIsInputDisabled(false));
      dispatch(resetLatestResponse());
      setDisplayedText('');
      dispatch(setFinalBotReply(null));
      if (isIdleEventEnabled) {
        handleIdleTimeout();
      }
    }
  }, [isStreamFinished, latestResponse, displayedText, finalBotReply]);

  useEffect(() => {
    scrollToBottom();
  }, [displayedText]);

  function isDisplayedTextFinished() {
    return (
      displayedText.split(' ').length === latestResponse.text.split(' ').length
    );
  }

  return (
    <div className='latest-response'>
      <p>
        {formatText(displayedText)}
        {!isStreamFinished || !isDisplayedTextFinished() ? (
          <span className='cursor'></span>
        ) : null}
      </p>
    </div>
  );
}

export default LatestResponse;

import { ConstantsType } from './types';

export const VITE_MODE = import.meta.env.MODE;
export const MSW_ENABLED = import.meta.env.VITE_MSW_ENABLED === '1' || false;
const hostname = window.location.hostname;
const rootConstants: ConstantsType = {
  CONFIGURATIONS_SERVICE_URL:
    import.meta.env.VITE_CONFIGURATIONS_SERVICE_URL || 'http://localhost:4000',
  MAX_FILE_SIZE: (import.meta.env.VITE_MAX_FILE_SIZE ?? 10) * 1024 * 1024,
  INPUT_LIMIT_BY_CHAR: import.meta.env.VITE_INPUT_LIMIT_BY_CHAR ?? 1000,
  LOCAL_FILE_STORAGE_ENABLED:
    import.meta.env.VITE_LOCAL_FILE_STORAGE_ENABLED === '1',
  INSAIT_LANDING_PAGE: 'https://insait.io/landing',
  CURRENT_GIT_SHA: import.meta.env.VITE_CURRENT_GIT_SHA,
  USE_NGINX_ENABLED: import.meta.env.VITE_USE_NGINX_ENABLED === '1',
  BASENAME: import.meta.env.VITE_BASENAME,
  BACKEND_PORT: import.meta.env.VITE_BACKEND_PORT,
  HOST: import.meta.env.VITE_BASENAME
    ? `${hostname}/${import.meta.env.VITE_BASENAME}`
    : `${hostname}${import.meta.env.VITE_USE_NGINX_ENABLED === '1' ? '/' : ''}`,
  TENANT: import.meta.env.VITE_TENANT,
  SENTRY_DSN:
    'https://9500ada30802ca81f292c12dd507e8e0@o4508047403909120.ingest.us.sentry.io/4508450116534272',
  HOSTNAME: hostname,
  RELEASE_VERSION: import.meta.env.VITE_RELEASE_VERSION,
  MAX_FILE_DISPLAY: 4,
};

const dependentConstants: ConstantsType = {
  BACKEND_URL: rootConstants.USE_NGINX_ENABLED
    ? `https://${rootConstants.HOST}api`
    : `http://${rootConstants.HOST}:${rootConstants.BACKEND_PORT}`,
};

const constants = {
  ...rootConstants,
  ...dependentConstants,
};

if (VITE_MODE === 'production') {
  Object.keys(constants).forEach((key) => {
    constants[key] = window._env_[key] ? window._env_[key] : constants[key];
  });
}

const getEnvironment = (hostname: string): string => {
  if (hostname.includes('insait.io')) {
    return 'production';
  } else if (hostname.includes('insait-internal.com')) {
    return 'staging';
  } else {
    return 'N/A';
  }
};
export const ENVIRONMENT = getEnvironment(hostname);

export const {
  CONFIGURATIONS_SERVICE_URL,
  MAX_FILE_SIZE,
  INPUT_LIMIT_BY_CHAR,
  LOCAL_FILE_STORAGE_ENABLED,
  INSAIT_LANDING_PAGE,
  CURRENT_GIT_SHA,
  USE_NGINX_ENABLED,
  BASENAME,
  BACKEND_PORT,
  HOST,
  BACKEND_URL,
  TENANT,
  SENTRY_DSN,
  HOSTNAME,
  RELEASE_VERSION,
  MAX_FILE_DISPLAY
} = constants;

import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PreviewFileItemProp, PreviewState } from '@utils/types';
import { checkLargeFile, getFileType, isValidPreviewType } from '@utils/files';
import utilsService from '@services/utilsService';
import { toast } from 'react-toastify';

export const displayPreview = createAsyncThunk(
  'filePreview/displayPreview',
  async (files: FileList , { rejectWithValue }) => {
    const results = [];
    const failures = [];

    // TODO: Change to be a send the entire file list when the backend update is made
    for (const file of Array.from(files)) {
      try {
        const fileType = getFileType(file);
        if (!fileType || !isValidPreviewType(fileType)) {
          failures.push({ file: file.name, error: 'Invalid file type' });
          continue;
        }

        const isLargeFile = checkLargeFile(file);
         if (isLargeFile) {
          failures.push({ file: file.name, error: 'File size exceeds 1MB' });
          continue;
        }

        const formData = new FormData();
        formData.append('file', file);
        const previewUrl = await utilsService.uploadFile(formData);
        results.push({ previewUrl, fileType });
      } catch (error) {
        failures.push({ file: file.name, error: 'Upload failed' });
      }
    }

    if (failures.length > 0) {
      toast.error(`Failed uploads: ${failures.map(f => f.file).join(', ')}`);
    }

    if (results.length === 0) {
      return rejectWithValue({ message: 'No files were successfully uploaded', failures });
    }

    return results;
  }
);

const initialState: PreviewState = {
  showPreview: false,
  showUploadButton: true,
  previewTitle: 'Upload Image',
  isLoading: false,
  previewFiles: []
};

export const filePreviewSlice = createSlice({
  name: 'filePreview',
  initialState,
  reducers: {
    setShowPreview: (state, action: PayloadAction<boolean>) => {
      state.showPreview = action.payload;
    },
    setShowUploadButton: (state, action: PayloadAction<boolean>) => {
      state.showUploadButton = action.payload;
    },
    setPreviewTitle: (state, action: PayloadAction<string>) => {
      state.previewTitle = action.payload;
    },
    setPreviewFiles: (state, action: PayloadAction<PreviewFileItemProp[]>) => {
      state.previewFiles = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(displayPreview.pending, (state) => {
        state.showPreview = true;
        state.isLoading = true;
      })
      .addCase(displayPreview.fulfilled, (state, action) => {
        if (state.showPreview) {
          state.previewFiles = action.payload;
          state.showUploadButton = true;
          state.previewTitle = '';
        }
        state.isLoading = false;
      })
      .addCase(displayPreview.rejected, (state) => {
        state.isLoading = false;
        state.showPreview = false;
      });
  },
});

export const {
  setShowPreview,
  setShowUploadButton,
  setPreviewTitle,
  setPreviewFiles,
  setIsLoading,
} = filePreviewSlice.actions;

export default filePreviewSlice.reducer;

import { useDispatch } from 'react-redux';
import { getFileType } from '@utils/files';
import {
  setPreviewTitle,
  setShowPreview,
  setShowUploadButton,
  setPreviewFiles,
} from '@states/slices/filePreviewSlices';
import { PreviewType } from '@utils/types';
import PdfPreview from '@components/PdfPreview';
import { MessageFileProps } from './Message.type';
import { MAX_FILE_DISPLAY } from '@/utils/constants';

function MessageFile({ files }: MessageFileProps) {
  const dispatch = useDispatch();
  const remainingFiles = files.length > MAX_FILE_DISPLAY ? files.length - MAX_FILE_DISPLAY : 0;
  const displayFiles = files.slice(0, MAX_FILE_DISPLAY);

  function handleFileClick() {
    dispatch(setPreviewTitle(''));
    dispatch(setShowPreview(true));
    dispatch(setShowUploadButton(false));
    const previewFiles = files.map(url => ({
      fileType: getFileType(url) as PreviewType,
      previewUrl: url
    }));
    dispatch(setPreviewFiles(previewFiles));
  }

  function DisplayFileFromUrl(url: string) {
    const urlFileType = getFileType(url);

    switch (urlFileType) {
      case 'image':
        return (
          <img
            className='chat-image'
            src={url}
            alt='Selected file'
            onClick={() => {
              if (url) {
                handleFileClick();
              }
            }}
          />
        );
      case 'video':
        return (
          <div
            className='video'
            onClick={(e) => {
              e.preventDefault();
              if (url) {
                handleFileClick();
              }
            }}
          >
            <video className='chat-video' src={url}>
              Your browser does not support the video tag.
            </video>
            <div className='play-overlay'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <polygon points='5 3 19 12 5 21 5 3'></polygon>
              </svg>
            </div>
          </div>
        );
      case 'pdf':
        return (
          <div className='chat-document'>
            <PdfPreview url={url} thumbnail={true} />
            <div
              onClick={(e) => {
                e.preventDefault();
                if (url) {
                  handleFileClick();
                }
              }}
              className='clickable-overlay'
            ></div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div className="message-file-container">
      {displayFiles.map((url, index) => (
        <div key={index} className="file-container">
          {DisplayFileFromUrl(url)}
          {index === MAX_FILE_DISPLAY - 1 && remainingFiles > 0 && (
            <div className="remaining-overlay" onClick={handleFileClick}>
              +{remainingFiles}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default MessageFile;

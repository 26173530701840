import './utils/sentry.ts';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './states/store';
import { MSW_ENABLED } from './utils/constants';

const container = document.getElementById('root')!;
const root = createRoot(container);

async function enableMocking() {
  if (!MSW_ENABLED) {
    return;
  }
  const { worker } = await import('../tests/mswUtils/browser');
  return worker.start();
}

enableMocking().then(() => {
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
});

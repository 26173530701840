import * as Sentry from '@sentry/react';
import {
  SENTRY_DSN,
  RELEASE_VERSION,
  VITE_MODE,
  ENVIRONMENT,
  TENANT,
} from '@utils/constants';
Sentry.init({
  dsn: SENTRY_DSN,
  release: RELEASE_VERSION,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    /^https:\/\/[^.]+\.insait\.io$/,
    /^https:\/\/[^.]+\.insait-internal\.com$/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: ENVIRONMENT,
  beforeSend(event) {
    if (VITE_MODE === 'development' || ENVIRONMENT === 'N/A') return null;
    return event;
  },
});
Sentry.setTag('tenant', TENANT);

import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import LoadingAnimation from '@components/LoadingAnimation';
import PdfPreview from '@components/PdfPreview';
import { PreviewType } from '@utils/types';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { useState } from 'react';
import './FilePreview.scss';

function FilePreview() {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  function renderFileContent(fileType: PreviewType, url: string) {
    switch (fileType) {
      case 'image':
        return <img src={url} alt='image' />;
      case 'video':
        return <video src={url} controls />;
      case 'pdf':
        return <PdfPreview url={url} thumbnail={false} />;
      default:
        return <div>Unsupported File Type</div>;
    }
  }

  const { previewFiles, isLoading } = useSelector(
    (state: RootState) => state.filePreview
  )

  function handlePrevious () {
    setCurrentIndex((prev) => (prev === 0 ? previewFiles.length - 1 : prev - 1))
  }

  function handleNext () {
    setCurrentIndex((prev) => (prev === previewFiles.length - 1 ? 0 : prev + 1))
  }

  function handleThumbnailClick (index: number) {
    setCurrentIndex(index)
  }

  if (isLoading) return <LoadingAnimation />
  if (!previewFiles.length) return null
  
  const currentFile = previewFiles[currentIndex]

  return (
    <div className='uploaded-file-box'>
      <div className='main-preview'>
        {previewFiles.length > 1 && (
          <button className='nav-button prev' onClick={handlePrevious}>
            <MdChevronLeft size={24} />
          </button>
        )}
        
        <div className='preview-content'>
          {currentFile.fileType === 'pdf' ? (
            renderFileContent(currentFile.fileType, currentFile.previewUrl)
          ) : (
            <TransformWrapper>
              <TransformComponent
                contentStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {renderFileContent(currentFile.fileType, currentFile.previewUrl)}
              </TransformComponent>
            </TransformWrapper>
          )}
        </div>
        {previewFiles.length > 1 && (
          <button className="nav-button next" onClick={handleNext}>
            <MdChevronRight size={24} />
          </button>
        )}
      </div>
      {previewFiles.length > 1 && (
        <div className="thumbnails">
          {previewFiles.map((file, index) => (
            <div
              key={index}
              className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleThumbnailClick(index)}
            >
              {renderFileContent(file.fileType, file.previewUrl)}
            </div>
          ))}
        </div>
      )}
  </div>
  );
}

export default FilePreview;

import { RootState, store } from '@/states/store';
import { v4 as uuidv4 } from 'uuid';
import { BotResponseType, ChatMessage } from './types';
import { useSelector } from 'react-redux';

export function waitFor(ms: number, sessionId: string) {
  return new Promise((resolve) => {
    const timeoutId = setTimeout(() => {
      const currentConversation = store.getState().chatbot.currentConversation;
      if (currentConversation === sessionId) {
        resolve(true);
      }
    }, ms);
    return () => clearTimeout(timeoutId);
  });
}

export function getCurrentMessageIndex(): number {
  const storedIndex = localStorage.getItem('customCurrentMessageIndex');
  return storedIndex ? parseInt(storedIndex, 10) : 0;
}

export function setCurrentMessageIndex(type: 'reset' | 'increase') {
  const value = type === 'reset' ? 1 : getCurrentMessageIndex() + 1;
  localStorage.setItem('customCurrentMessageIndex', value.toString());
}

export function eraseCurrentMessageIndex() {
  localStorage.removeItem('customCurrentMessageIndex');
}

export async function getCustomChatbotReply(
  isFirstMessage: boolean,
  sessionId: string
) {
  const currentMessageIndex = getCurrentMessageIndex();
  const settingState = store.getState().settings;
  const botConversationFlow = settingState.isUserAutoResponseEnabled
    ? settingState.conversationFlow
    : settingState.conversationFlow.filter((item) => item.pov === 'bot');
  const DEFAULT_AI_BOT_RESPONSE =
    'Thank you for your interest. Is there anything else I can help you with?';

  const response: BotResponseType = {
    messageId: uuidv4(),
    uploadEnabled: true,
    botReply: DEFAULT_AI_BOT_RESPONSE,
    pov: 'bot',
    chatStatus: 'ACTIVE',
    botLead: null,
    suggestedQuestions: null,
    frequentlyAskedQuestions: null,
    postMessageData: null,
  };

  function updateResponseFromBotFlow(index: number) {
    const botResponse = botConversationFlow[index];
    response.botReply = botResponse.text;
    response.botReplyFiles = botResponse.files?.map(file => file.previewUrl);
  }

  if (isFirstMessage) {
    updateResponseFromBotFlow(0);
    response.uploadEnabled = false;
    setCurrentMessageIndex('reset');
  } else if (currentMessageIndex < botConversationFlow.length) {
    await waitFor(settingState.botWaitSeconds * 1000, sessionId);
    updateResponseFromBotFlow(currentMessageIndex);
    setCurrentMessageIndex('increase');
  }

  if (currentMessageIndex === botConversationFlow.length - 1) {
    response.chatStatus = 'FINISHED';
  }

  return response;
}

export function generateChatMessage(
  text: string,
  fileUrl: string[]
): ChatMessage {
  const message_id = uuidv4();
  const newUserMessage: ChatMessage = {
    id: message_id,
    text: text,
    files: fileUrl,
    pov: 'user',
    time: new Date().toISOString(),
  };
  return newUserMessage;
}

export function useAutoFlow(): boolean {
  const { isAutoFlowEnabled, productSelection } = useSelector(
    (state: RootState) => state.settings
  );

  return isAutoFlowEnabled && productSelection === 'custom';
}

import { useDispatch, useSelector } from 'react-redux';
import { ClipboardEvent, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { AppDispatch, RootState } from '@states/store';
import {
  isStreamingWaitingInProgress,
  setIsInputSent,
  setIsQuestionInput,
  setUserInput,
} from '@states/slices/chatbotSlice';

import { INPUT_LIMIT_BY_CHAR } from '@utils/constants';
import { toast } from 'react-toastify';
import { displayPreview } from '@states/slices/filePreviewSlices';
import './ChatInput.scss';
import { createFileList, handleDragOver, handleFileDrop } from '@utils/files';
import { sanitiseInput } from '@/utils/utils';

function ChatInput() {
  const {
    chatStatus,
    userInput,
    isUploadEnabled,
    currentConversation,
    showTransferChat,
    openChatWindow,
    isInputSent,
    isQuestionInput,
  } = useSelector((state: RootState) => state.chatbot);
  const {
    currentLanguage,
    inputFieldPlaceholder,
    isStreamingEnabled,
    isMobileModeEnabled,
  } = useSelector((state: RootState) => state.settings);
  const { showPreview } = useSelector((state: RootState) => state.filePreview);
  const dispatch: AppDispatch = useDispatch();
  const inputDisabled = useSelector(isStreamingWaitingInProgress);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  function handlePasteText(
    event: ClipboardEvent<HTMLTextAreaElement>,
    text: string
  ) {
    const inputField = event.target as HTMLInputElement;
    const start = inputField.selectionStart ?? 0;
    const end = inputField.selectionEnd ?? 0;
    const currentValue = inputField.value;
    const newValue =
      currentValue.substring(0, start) + text + currentValue.substring(end);
    if (newValue.length > INPUT_LIMIT_BY_CHAR) {
      toast.error(currentLanguage.limit_exceeded);
      return;
    }
    inputField.value = newValue;
    inputField.selectionStart = inputField.selectionEnd = start + text.length;
    dispatch(setUserInput(newValue));
  }

  function handlePaste(event: ClipboardEvent<HTMLTextAreaElement>) {
    event.preventDefault();
    const items = event.clipboardData?.items;
    if (!items) return;

    const item = Array.from(items).find(
      (item) => item.kind === 'string' || item.kind === 'file'
    );

    if (!item) return;

    if (item.kind === 'string') {
      item.getAsString((text) => {
        handlePasteText(event, text);
      });
      return;
    }

    if (item.kind === 'file') {
      if (!isUploadEnabled) {
        toast.error(currentLanguage.upload.disabled);
        dispatch(setUserInput(''));
        return;
      }

      const file = item.getAsFile();
      if (file) {
        const fileListData = createFileList(file);
        dispatch(displayPreview(fileListData));
      } else {
        toast.error(currentLanguage.upload.error);
      }
    }
  }

  // TODO: Add any additional useEffect dependencies we'd want the input bar to be focussed after
  useEffect(() => {
    if (isMobileModeEnabled) return;
    if (inputRef.current) {
      inputRef.current.focus();
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  }, [
    openChatWindow,
    currentConversation,
    showTransferChat,
    showPreview,
    isInputSent,
    isQuestionInput,
    isMobileModeEnabled,
  ]);

  return (
    <div
      className='chat-input-container'
      onDragOver={handleDragOver}
      onDrop={(event) =>
        handleFileDrop(event, dispatch, isUploadEnabled, currentLanguage)
      }
    >
      <TextareaAutosize
        ref={inputRef}
        className='user-input'
        autoFocus={!isMobileModeEnabled}
        maxRows={3}
        placeholder={inputFieldPlaceholder}
        aria-label={inputFieldPlaceholder}
        disabled={chatStatus === 'FINISHED' || chatStatus === 'TRANSFERRED'}
        value={userInput}
        onChange={(e) => {
          if (e.target.value.length > INPUT_LIMIT_BY_CHAR) {
            toast.error(currentLanguage.limit_exceeded);
            return;
          }
          dispatch(setUserInput(e.target.value));
        }}
        onPaste={(e) => {
          handlePaste(e);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault();
            dispatch(setUserInput(userInput + '\n'));
          } else if (
            (e.code === 'Enter' || e.code === 'NumpadEnter') &&
            (!isStreamingEnabled || !inputDisabled)
          ) {
            e.preventDefault();
            dispatch(setUserInput(sanitiseInput(userInput, currentLanguage)));
            dispatch(setIsInputSent(true));
            dispatch(setIsQuestionInput(false));
          }
        }}
      />
    </div>
  );
}

export default ChatInput;

import apiClient from '@utils/axios';
import { BACKEND_URL } from '@utils/constants';

const chatbotService = {
  async getChatbotReply(
    MessageId: string,
    userMessage: string,
    isQuestionInput: boolean,
    currentUser: string,
    currentConversation: string,
    language: string,
    product: string,
    variant: string,
    isFirstMessage: boolean,
    userFiles: string[] = []
  ) {
    try {
      const response = await apiClient.post(`${BACKEND_URL}/chatbot/message`, {
        body: userMessage,
        isQuestionInput: isQuestionInput,
        userId: currentUser,
        conversationId: currentConversation,
        messageId: MessageId,
        language: language,
        product: product,
        variant: variant,
        isFirstMessage: isFirstMessage,
        fileUrl: userFiles,
      });

      const {
        ai_response: botReply,
        ai_response_file: botReplyFiles,
        ai_response_id: messageId,
        chat_status: chatStatus,
        bot_lead: botLead,
        suggested_questions: suggestedQuestions,
        upload_enabled: uploadEnabled,
        post_message_data: postMessageData,
        frequently_asked_questions: FrequentlyAskedQuestions,
      } = response.data;
      if (!botReply && botReply !== '') throw new Error('No response from bot');
      return {
        botReply,
        botReplyFiles,
        messageId,
        chatStatus,
        botLead,
        suggestedQuestions,
        uploadEnabled,
        postMessageData,
        FrequentlyAskedQuestions,
      };
    } catch (error) {
      throw error;
    }
  },
  async transferConversationToCustomerService(
    currentUser: string,
    currentConversation: string
  ) {
    try {
      const response = await apiClient.post(`${BACKEND_URL}/chatbot/transfer`, {
        userId: currentUser,
        conversationId: currentConversation,
      });
      if (response.status !== 200) throw new Error('No response from bot');
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async fetchPreviewMessage(
    language: string,
    product: string,
    variant: string
  ) {
    try {
      const response = await apiClient.get(`${BACKEND_URL}/chatbot/preview`, {
        params: { language, product, variant },
      });

      if (response.status !== 200) throw new Error('No response from bot');
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },
};

export default chatbotService;

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@states/store';
import 'react-toastify/dist/ReactToastify.css';
import Chatbot from '@components/Chatbot';
import PasswordCheck from '@components/PasswordCheck';
import Modal from '@components/Modal';
import Form from '@pages/Settings';
import FilePreview from '@components/FilePreview';
import {
  isStreamingWaitingInProgress,
  setIsFileSent,
  setShowSettings,
  setShowTransferChat,
} from '@states/slices/chatbotSlice';
import {
  setShowPreview,
  setPreviewFiles,
  setPreviewTitle,
} from '@states/slices/filePreviewSlices';
import useCurrentSkin from '@hooks/useCurrentSkin';
import './ChatContainer.scss';
import useViewPortChange from '@/hooks/useViewPortChange';
import TransferModal from '../ChatHeader/TransferChat/TransferModal';

function ChatContainer() {
  const {
    isDemoModeEnabled,
    isEmbeddedModeEnabled,
    isFullScreenModeEnabled,
    isPasswordEnabled,
    currentLanguage,
  } = useSelector((state: RootState) => state.settings);
  const { isKeyboardOpen } = useViewPortChange();

  const {
    chatStatus,
    openChatWindow,
    isValidUser,
    showSettings,
    showTransferChat,
  } = useSelector((state: RootState) => state.chatbot);
  const isInputStreamingDisabled = useSelector(isStreamingWaitingInProgress);

  const { showPreview, showUploadButton, previewTitle, isLoading } =
    useSelector((state: RootState) => state.filePreview);

  const currentSkin = useCurrentSkin();

  const dispatch = useDispatch();

  function closeSettings() {
    dispatch(setShowSettings(false));
  }

  function handleUpload() {
    dispatch(setIsFileSent(true));
    dispatch(setPreviewTitle(''));
    dispatch(setShowPreview(false));
  }

  const Header = currentSkin.components.header;

  return (
    <>
      <div
        className={`${currentSkin.css.mainContainer} ${
          isDemoModeEnabled &&
          !isEmbeddedModeEnabled &&
          !isFullScreenModeEnabled
            ? 'mc2'
            : ''
        } ${openChatWindow === 'OPEN' ? 'active' : ''} ${
          isFullScreenModeEnabled ? 'fullscreen' : ''
        } ${isKeyboardOpen && !showSettings ? 'keyboard-open-state' : ''}`}
      >
        <Header />
        {!isValidUser && isPasswordEnabled && !isEmbeddedModeEnabled ? (
          <PasswordCheck />
        ) : (
          <Chatbot />
        )}
      </div>

      <Modal title='Bot settings' isOpen={showSettings} onClose={closeSettings}>
        <Form />
      </Modal>

      <Modal
        title={currentLanguage.transfer.title}
        isOpen={showTransferChat}
        onClose={() => dispatch(setShowTransferChat(false))}
      >
        <TransferModal />
      </Modal>

      <div className={isLoading ? 'modal loading' : 'modal'}>
        <Modal
          title={showPreview ? previewTitle : ''}
          isOpen={showPreview}
          onClose={() => {
            dispatch(setShowPreview(false));
            dispatch(setPreviewFiles([]));
          }}
          stickyContent={
            showUploadButton ? (
              <div style={{ textAlign: 'center' }}>
                <button
                  className='modal-upload-button'
                  disabled={
                    chatStatus === 'FINISHED' || isInputStreamingDisabled
                  }
                  onClick={() => {
                    if (showPreview) {
                      handleUpload();
                    }
                  }}
                >
                  {currentLanguage.upload.button}
                </button>
              </div>
            ) : null
          }
        >
          {showPreview ? <FilePreview /> : null}
        </Modal>
      </div>
    </>
  );
}

export default ChatContainer;

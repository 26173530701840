import apiClient from '@utils/axios';
import { BACKEND_URL } from '@utils/constants';

const usersService = {
  async addNewUser(
    user_id: string,
    external_id: string,
    first_name: string,
    last_name: string,
    chatbot_visible: boolean,
    extra_data: { [key: string]: any } | null
  ) {
    try {
      const response = await apiClient.post(`${BACKEND_URL}/users/${user_id}`, {
        external_id,
        first_name,
        last_name,
        chatbot_visible,
        extra_data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async getSavedUserInfo(user_id: string) {
    try {
      const response = await apiClient.get(`${BACKEND_URL}/users/${user_id}`);
      const { user_found: userFound, user_data: userData } = response.data;
      return { userFound, userData };
    } catch (error) {
      throw error;
    }
  },
  async updateUserInfo(
    user_id: string,
    external_id: string,
    first_name: string,
    last_name: string,
    chatbot_visible?: boolean
  ) {
    try {
      const response = await apiClient.put(`${BACKEND_URL}/users/${user_id}`, {
        external_id: external_id,
        first_name: first_name,
        last_name: last_name,
        chatbot_visible: chatbot_visible,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
export default usersService;

import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@states/store';
import {
  setConversationFlow,
  updateSettings,
} from '@states/slices/settingsSlice';
import { setShowSettings, restartChat } from '@states/slices/chatbotSlice';
import {
  SettingsState,
  SettingsFormData,
  SkinName,
  ConversationFlowType,
} from '@utils/types';
import { setTextDirection } from '@utils/utils';
import { getFileType } from '@utils/files';
import utilsService from '@services/utilsService';
import { setCurrentSkin } from '@states/slices/skinsSlice';
import { skins } from '@/skins/index';
import '@styles/pages/Settings.scss';

function Form() {
  const settings = useSelector((state: RootState) => state.settings);
  const { currentSkinName } = useSelector((state: RootState) => state.skins);
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = useState<SettingsState>({
    ...settings,
    skinName: currentSkinName,
  });

  const [isUploadingBotImage, setIsUploadingBotImage] =
    useState<boolean>(false);
  const [isUploadingBotBackground, setIsUploadingBotBackground] =
    useState(false);
  const [botImageFileName, setBotImageFileName] = useState<string>('');
  const [botBackgroundFileName, setBotBackgroundFileName] =
    useState<string>('');
  const [conversationFlowString, setConversationFlowString] = useState(
    JSON.stringify(settings.conversationFlow, null, 2)
  );

  useEffect(() => {
    setTextDirection('.form-section', 'ltr');
  }, []);

  async function handleSave(event: React.FormEvent) {
    event.preventDefault();
    const updatedSettings = handleNewSettings();

    if (!updatedSettings) return;
    if (formData.productSelection === 'custom') {
      const conversationFlow = handleParseConversationFlow();
      if (!Array.isArray(conversationFlow)) {
        return;
      }
    }
    dispatchUpdate(updatedSettings);
    if (formData.productSelection === 'custom') {
      handleRestartChat('conversation flow');
    } else if (
      formData.languageSelection !== settings.languageSelection &&
      formData.productSelection !== settings.productSelection
    ) {
      handleRestartChat('language and product');
    } else {
      if (formData.languageSelection !== settings.languageSelection) {
        handleRestartChat('language');
      }
      if (formData.productSelection !== settings.productSelection) {
        handleRestartChat('product');
      }
    }
    toast.success('Chatbot settings saved');
  }

  async function uploadSettingsFile(file: File, key: keyof SettingsFormData) {
    const formData = new FormData();
    formData.append('file', file);
    if (key === 'botImage') {
      setIsUploadingBotImage(true);
    } else if (key === 'botBackground') {
      setIsUploadingBotBackground(true);
    }
    const url = await utilsService.uploadFile(formData);
    if (url) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [key]: url,
      }));
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [key]: '' }));
    }
    if (key === 'botImage') {
      setIsUploadingBotImage(false);
    } else if (key === 'botBackground') {
      setIsUploadingBotBackground(false);
    }
  }

  function handleChange(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) {
    const { name, value } = event.target;
    if (name === 'isDemoModeEnabled' || name === 'isUserAutoResponseEnabled') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value === '1',
      }));
    } else if (name === 'conversationFlow') {
      setConversationFlowString(value);
    } else {
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    }
  }

  function handleBotImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      if (getFileType(file) === 'image') {
        setBotImageFileName(file.name);
        uploadSettingsFile(file, 'botImage');
      } else {
        toast.error('Invalid file type. Please upload an image file');
        return;
      }
    }
  }

  function handleBotBackgroundChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const file = event.target.files?.[0];
    if (file) {
      if (getFileType(file) === 'image') {
        setBotBackgroundFileName(file.name);
        uploadSettingsFile(file, 'botBackground');
      } else {
        toast.error('Invalid file type. Please upload an image file');
        return;
      }
    }
  }

  function handleNewSettings() {
    const { currentLanguage, ...formDataToSave } = formData;
    const updatedSettings: SettingsFormData = {
      ...formDataToSave,
      botImage: formData.botImage || '',
      botBackground: formData.botBackground || '',
    };

    return updatedSettings;
  }

  function handleParseConversationFlow() {
    try {
      const parsedConversationFlow: ConversationFlowType[] = JSON.parse(
        conversationFlowString
      );
      dispatch(setConversationFlow(parsedConversationFlow));
      return parsedConversationFlow;
    } catch (error) {
      toast.error('Invalid JSON in Conversation Flow');
      return null;
    }
  }

  function dispatchUpdate(updatedSettings: SettingsFormData) {
    try {
      dispatch(updateSettings(updatedSettings as SettingsState));
      dispatch(setCurrentSkin(updatedSettings.skinName as SkinName));
      dispatch(setShowSettings(false));
    } catch (error) {
      toast.error('Error saving bot settings: ' + error);
    }
  }

  function handleRestartChat(type: string) {
    dispatch(restartChat());
    toast.info(`Chatbot ${type} changed. Restarting chat`);
  }

  return (
    <section className='form-section'>
      <div className='container clear'>
        <div className='custom-form'>
          <form onSubmit={handleSave}>
            <div className='row'>
              <div className='col-full'>
                <label htmlFor='botName'>Bot Name:</label>
                <input
                  type='text'
                  id='botName'
                  name='botName'
                  value={formData.botName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-full'>
                <label>Bot Logo Image:</label>
                {isUploadingBotImage ? (
                  <label htmlFor='botImage' className='custom-file-upload'>
                    <div className='typing'>
                      <div
                        className='dot'
                        style={{ backgroundColor: settings.color1 }}
                      ></div>
                      <div
                        className='dot'
                        style={{ backgroundColor: settings.color1 }}
                      ></div>
                      <div
                        className='dot'
                        style={{ backgroundColor: settings.color1 }}
                      ></div>
                    </div>
                  </label>
                ) : (
                  <label htmlFor='botImage' className='custom-file-upload'>
                    {`${botImageFileName}` || 'Click to change bot image'}{' '}
                  </label>
                )}
                <input
                  className='file-input'
                  id='botImage'
                  name='botImage'
                  type='file'
                  accept='image/*'
                  onChange={handleBotImageChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-full'>
                <label>Bot Background Image:</label>
                {isUploadingBotBackground ? (
                  <label htmlFor='botBackground' className='custom-file-upload'>
                    <div className='typing'>
                      <div
                        className='dot'
                        style={{ backgroundColor: settings.color1 }}
                      ></div>
                      <div
                        className='dot'
                        style={{ backgroundColor: settings.color1 }}
                      ></div>
                      <div
                        className='dot'
                        style={{ backgroundColor: settings.color1 }}
                      ></div>
                    </div>
                  </label>
                ) : (
                  <label htmlFor='botBackground' className='custom-file-upload'>
                    {`${botBackgroundFileName}` ||
                      'Click to change bot background'}{' '}
                  </label>
                )}
                <input
                  className='file-input'
                  id='botBackground'
                  name='botBackground'
                  type='file'
                  accept='image/*'
                  onChange={handleBotBackgroundChange}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-half'>
                <label htmlFor='color1'>Color 1:</label>
                <input
                  type='color'
                  id='color1'
                  name='color1'
                  className='color-picker'
                  value={formData.color1}
                  onChange={handleChange}
                  required
                />
                <label htmlFor='color1' className='color-input'>
                  <p style={{ background: formData.color1 }}></p>
                  <span>{formData.color1}</span>
                </label>
              </div>

              <div className='col-half'>
                <label htmlFor='color2'>Color 2:</label>
                <input
                  type='color'
                  id='color2'
                  name='color2'
                  className='color-picker'
                  value={formData.color2}
                  onChange={handleChange}
                />
                <label htmlFor='color2' className='color-input'>
                  <p style={{ background: formData.color2 }}></p>
                  <span>{formData.color2}</span>
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col-half'>
                <label htmlFor='productSelection'>Product:</label>
                <select
                  id='productSelection'
                  name='productSelection'
                  value={formData.productSelection}
                  onChange={handleChange}
                  required
                >
                  <option hidden></option>
                  <option value='allot'>Allot</option>
                  <option value='bruntwork'>Bruntwork</option>
                  <option value='demo_debt_bot'>
                    Demo Debt Consolidation Agent
                  </option>
                  <option value='etoro'>eToro</option>
                  <option value='glidepath'>Glidepath</option>
                  <option value='great_synagogue'>The Great Synagogue</option>
                  <option value='hyp'>Hyp</option>
                  <option value='ilending'>iLending</option>
                  <option value='insait'>Insait</option>
                  <option value='know_your_customer'>Know Your Customer</option>
                  <option value='leumi_one_time_deposit'>
                    Leumi One Time Deposit
                  </option>
                  <option value='meir'>Meir</option>
                  <option value='meir_0_kilometer'>Meir zero kilometer</option>
                  <option value='ntrustus'>nTrustus</option>
                  <option value='ondeck'>OnDeck</option>
                  <option value='phoenix'>Phoenix</option>
                  <option value='rag_demo'>Rag Demo Bot</option>
                  <option value='revive'>Revive</option>
                  <option value='savings_account_english'>
                    Savings Account English
                  </option>
                  <option value='savings_account_hebrew'>
                    Savings Account Hebrew
                  </option>
                  <option value='service_seeking'>Service Seeking</option>
                  <option value='tradie_va'>TradieVA</option>
                  <option value='wobi'>Wobi</option>
                  {settings.isAutoFlowEnabled ? (
                    <option value='custom'>Custom</option>
                  ) : null}
                </select>
              </div>

              <div className='col-half'>
                <label htmlFor='languageSelection'>Language:</label>
                <select
                  id='languageSelection'
                  name='languageSelection'
                  value={formData.languageSelection}
                  onChange={handleChange}
                  required
                >
                  <option hidden></option>
                  <option value='hebrew'>Hebrew</option>
                  <option value='english'>English</option>
                  {![
                    'money_transfer',
                    'business_loan',
                    'car_loan',
                    'wobi',
                  ].includes(formData.productSelection) ? (
                    <option value='spanish'>Spanish</option>
                  ) : null}
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-half'>
                <label htmlFor='isDemoModeEnabled'>Demo Mode:</label>
                <select
                  id='isDemoModeEnabled'
                  name='isDemoModeEnabled'
                  value={formData.isDemoModeEnabled ? '1' : '0'}
                  onChange={handleChange}
                  required
                >
                  <option hidden></option>
                  <option value='0'>Off</option>
                  <option value='1'>On</option>
                </select>
              </div>
              <div className='col-half'>
                <label htmlFor='skinName'>UI Skin:</label>
                <select
                  id='skinName'
                  name='skinName'
                  value={formData.skinName}
                  onChange={handleChange}
                >
                  {Object.keys(skins).map((skin) => (
                    <option key={skin} value={skin}>
                      {skin}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {settings.isAutoFlowEnabled &&
              formData.productSelection === 'custom' && (
                <>
                  <div className='row'>
                    <div className='col-full'>
                      <label htmlFor='userAutoResponse'>
                        User Auto Response
                      </label>
                      <select
                        id='isUserAutoResponseEnabled'
                        name='isUserAutoResponseEnabled'
                        value={formData.isUserAutoResponseEnabled ? '1' : '0'}
                        onChange={handleChange}
                      >
                        <option hidden></option>
                        <option value='0'>Off</option>
                        <option value='1'>On</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-half'>
                      <label htmlFor='botWaitSeconds'>Bot Wait Seconds:</label>
                      <input
                        type='number'
                        id='botWaitSeconds'
                        name='botWaitSeconds'
                        value={formData.botWaitSeconds}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className='col-half'>
                      <label htmlFor='userWaitSeconds'>
                        User Wait Seconds:
                      </label>
                      <input
                        type='number'
                        id='userWaitSeconds'
                        name='userWaitSeconds'
                        value={formData.userWaitSeconds}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-full'>
                      <label htmlFor='conversationFlow'>
                        Conversation Flow
                      </label>
                      <textarea
                        id='conversationFlow'
                        name='conversationFlow'
                        onChange={handleChange}
                        value={conversationFlowString}
                        rows={20}
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            <button
              disabled={isUploadingBotImage || isUploadingBotBackground}
              type='submit'
            >
              Save Settings
            </button>
          </form>
        </div>
      </div>
      {settings.isErrorDisplayEnabled && !settings.isSettingsEnabled ? (
        <ToastContainer autoClose={5000} theme='light' />
      ) : null}
    </section>
  );
}

export default Form;

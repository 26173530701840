import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConversationFlowType, SettingsState } from '../../utils/types';
import AgentImage from '../../assets/images/agent.png';
import DefaultBotImage from '../../assets/images/logo-default.png';
import BotBackground from '../../assets/images/bg.png';
import { changeLanguage, setHtmlLanguage, setColor } from '@utils/utils';
import { initialConversationFlow } from '@/assets/data/conversationFlow';

function getInitialLanguage() {
  const language = 'english';
  const currentLanguage = changeLanguage(language);
  return currentLanguage;
}

const initialState: SettingsState = {
  botName: 'Example Bot',
  botImage: DefaultBotImage, // TODO: Add functionality for default bot image for other skins
  agentImage: AgentImage,
  footerLogo: null,
  buttonText: "We're here to help",
  botBackground: BotBackground,
  color1: '',
  color2: '',
  botNameColor: null,
  botMessageColor: null,
  userMessageColor: null,
  productSelection: 'savings_account_english',
  variantSelection: 'default',
  languageSelection: 'english',
  isDemoModeEnabled: true,
  currentLanguage: getInitialLanguage(),
  isEmbeddedModeEnabled: false,
  isFullScreenModeEnabled: false,
  isSettingsEnabled: false,
  isPasswordEnabled: true,
  isRestartEnabled: false,
  isTransferEnabled: false,
  isPreviewEnabled: true,
  isDefaultOpenEnabled: false,
  isDefaultOpenMobileEnabled: false,
  isAddNewConversationEnabled: false,
  isMobileModeEnabled: false,
  isErrorDisplayEnabled: true,
  isFreemiumModeEnabled: true,
  isAuthedUsersOnlyEnabled: false,
  abTestPercentage: 100,
  isLikeButtonsEnabled: true,
  isIdleEventEnabled: false,
  idleEventTimeoutInMinutes: 10,
  isOnlineStatusEnabled: true,
  isFileSendEnabled: true,
  isEmojiSendEnabled: true,
  skinName: 'default',
  conversationFlow: initialConversationFlow,
  isAutoFlowEnabled: false,
  isUserAutoResponseEnabled: false,
  botWaitSeconds: 5,
  userWaitSeconds: 2,
  inputFieldPlaceholder: '',
  isLikeFeedbackEnabled: false,
  isFeedbackSummaryEnabled: false,
  isMobileAgentEnabled: true,
  isFeedbackMessageEnabled: true,
  isFeedbackRatingEnabled: true,
  isStreamingEnabled: true,
  isConfigurationLoaded: false,
  isInitEventEnabled: false,
  fullStoryOrgId: '',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<SettingsState>>) => {
      const payload = action.payload;

      state.abTestPercentage =
        payload.abTestPercentage ?? state.abTestPercentage;
      state.botName = payload.botName ?? state.botName;
      state.botImage = payload.botImage ?? state.botImage;
      state.botBackground = payload.botBackground ?? state.botBackground;
      state.footerLogo = payload.footerLogo ?? state.footerLogo;
      state.color1 = payload.color1 ?? state.color1;
      state.color2 = payload.color2 ?? state.color2;
      state.botNameColor = payload.botNameColor ?? state.botNameColor;
      state.botMessageColor = payload.botMessageColor ?? state.botMessageColor;
      state.userMessageColor =
        payload.userMessageColor ?? state.userMessageColor;
      state.buttonText = payload.buttonText ?? state.buttonText;
      state.inputFieldPlaceholder =
        payload.inputFieldPlaceholder ?? state.inputFieldPlaceholder;
      state.productSelection =
        payload.productSelection ?? state.productSelection;
      state.variantSelection =
        payload.variantSelection ?? state.variantSelection;

      state.isStreamingEnabled =
        payload.isStreamingEnabled ?? state.isStreamingEnabled;

      state.isDemoModeEnabled =
        payload.isDemoModeEnabled ?? state.isDemoModeEnabled;
      state.isErrorDisplayEnabled =
        payload.isErrorDisplayEnabled ?? state.isErrorDisplayEnabled;
      state.isFreemiumModeEnabled =
        payload.isFreemiumModeEnabled ?? state.isFreemiumModeEnabled;
      state.isFullScreenModeEnabled =
        payload.isFullScreenModeEnabled ?? state.isFullScreenModeEnabled;
      state.isPasswordEnabled =
        payload.isPasswordEnabled ?? state.isPasswordEnabled;
      state.isSettingsEnabled =
        payload.isSettingsEnabled ?? state.isSettingsEnabled;
      state.isRestartEnabled =
        payload.isRestartEnabled ?? state.isRestartEnabled;
      state.isTransferEnabled =
        payload.isTransferEnabled ?? state.isTransferEnabled;

      state.isPreviewEnabled =
        payload.isPreviewEnabled ?? state.isPreviewEnabled;
      state.isDefaultOpenEnabled =
        payload.isDefaultOpenEnabled ?? state.isDefaultOpenEnabled;
      state.isDefaultOpenMobileEnabled =
        payload.isDefaultOpenMobileEnabled ?? state.isDefaultOpenMobileEnabled;

      state.isAddNewConversationEnabled =
        payload.isAddNewConversationEnabled ??
        state.isAddNewConversationEnabled;
      state.isAuthedUsersOnlyEnabled =
        payload.isAuthedUsersOnlyEnabled ?? state.isAuthedUsersOnlyEnabled;
      state.isLikeButtonsEnabled =
        payload.isLikeButtonsEnabled ?? state.isLikeButtonsEnabled;
      state.isAutoFlowEnabled =
        payload.isAutoFlowEnabled ?? state.isAutoFlowEnabled;
      state.isIdleEventEnabled =
        payload.isIdleEventEnabled ?? state.isIdleEventEnabled;
      state.idleEventTimeoutInMinutes =
        payload.idleEventTimeoutInMinutes ?? state.idleEventTimeoutInMinutes;
      state.isOnlineStatusEnabled =
        payload.isOnlineStatusEnabled ?? state.isOnlineStatusEnabled;
      state.isFileSendEnabled =
        payload.isFileSendEnabled ?? state.isFileSendEnabled;
      state.isEmojiSendEnabled =
        payload.isEmojiSendEnabled ?? state.isEmojiSendEnabled;
      state.isLikeFeedbackEnabled =
        payload.isLikeFeedbackEnabled ?? state.isLikeFeedbackEnabled;
      state.isFeedbackSummaryEnabled =
        payload.isFeedbackSummaryEnabled ?? state.isFeedbackSummaryEnabled;
      state.isMobileAgentEnabled =
        payload.isMobileAgentEnabled ?? state.isMobileAgentEnabled;
      state.isFeedbackMessageEnabled =
        payload.isFeedbackMessageEnabled ?? state.isFeedbackMessageEnabled;
      state.isFeedbackRatingEnabled =
        payload.isFeedbackRatingEnabled ?? state.isFeedbackRatingEnabled;
      state.isInitEventEnabled =
        payload.isInitEventEnabled ?? state.isInitEventEnabled;

      state.isUserAutoResponseEnabled =
        payload.isUserAutoResponseEnabled ?? state.isUserAutoResponseEnabled;

      state.botWaitSeconds = Number(
        payload.botWaitSeconds ?? state.botWaitSeconds
      );
      state.userWaitSeconds = Number(
        payload.userWaitSeconds ?? state.userWaitSeconds
      );
      state.fullStoryOrgId = payload.fullStoryOrgId ?? state.fullStoryOrgId;

      if (payload.languageSelection) {
        state.languageSelection = payload.languageSelection;
        const currentLanguage = changeLanguage(state.languageSelection);
        state.currentLanguage = currentLanguage;
        setHtmlLanguage(payload.languageSelection);
      }

      setColor('primary', payload.color1 ?? state.color1);
      setColor('secondary', payload.color2 ?? state.color2);
      setColor('bot-message', payload.botMessageColor ?? state.botMessageColor);
      setColor(
        'user-message',
        payload.userMessageColor ?? state.userMessageColor
      );
    },
    setIsEmbeddedModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isEmbeddedModeEnabled = action.payload;
    },
    setIsMobileModeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isMobileModeEnabled = action.payload;
    },
    setConversationFlow: (
      state,
      action: PayloadAction<ConversationFlowType[]>
    ) => {
      state.conversationFlow = action.payload;
    },
    setIsConfigurationLoaded: (state, action: PayloadAction<boolean>) => {
      state.isConfigurationLoaded = action.payload;
    },
  },
});

export const {
  updateSettings,
  setIsEmbeddedModeEnabled,
  setIsMobileModeEnabled,
  setConversationFlow,
  setIsConfigurationLoaded,
} = settingsSlice.actions;
export default settingsSlice.reducer;

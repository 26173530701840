import { toast } from 'react-toastify';
import { DragEvent } from 'react';
import { AppDispatch } from '@/states/store';
import { displayPreview } from '@/states/slices/filePreviewSlices';
import { ChatLanguage, PreviewType } from './types';
import { MAX_FILE_SIZE } from './constants';


export function getFileSrc(file: File | string | null): string {
  if (file instanceof File) {
    return URL.createObjectURL(file);
  } else if (typeof file === 'string') {
    return file;
  } else {
    return '';
  }
}

export function getFileType(file: File | string) {
  const validTypes = {
    image: ['jpeg', 'png', 'gif', 'jpg'],
    video: ['mp4', 'webm', 'ogg', 'quicktime', 'mov', 'mpeg', 'avi'],
    pdf: ['pdf'],
  };

  const fileExtension =
    file instanceof File
      ? file.type.split('/').pop()
      : file.split('.').pop()?.split('?')[0];

  if (!fileExtension) {
    toast.error('Invalid file format');
    return;
  }
  for (const [type, extensions] of Object.entries(validTypes)) {
    if (extensions.includes(fileExtension)) {
      return type;
    }
  }
  toast.error('Invalid file format');
}

function getFileSizeFormat(bytes: number): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const size = bytes / Math.pow(1024, i);
  return `${size % 1 === 0 ? size.toFixed(0) : size.toFixed(2)} ${sizes[i]}`;
}

export function checkLargeFile(file: File) {
  if (file.size > MAX_FILE_SIZE) {
    toast.error(`File size exceeds ${getFileSizeFormat(MAX_FILE_SIZE)}`);
    return true;
  }
  return false;
}

export function isValidPreviewType(type: string): type is PreviewType {
  return ['image', 'video', 'pdf'].includes(type);
}

export const fileTypeHandlers = [
  {
    fileType: 'image',
    validation: (file: File) => getFileType(file) === 'image',
    errorMessage: 'Invalid file format, please upload a PNG or JPEG file.',
  },
  {
    fileType: 'video',
    validation: (file: File) => getFileType(file) === 'video',
    errorMessage: 'Invalid file format. Please upload an MP4 or WEBM file',
  },
];

async function getFolderContents(dataTransferItems: DataTransferItemList | null): Promise<FileList> {
  if (!dataTransferItems || dataTransferItems.length === 0) {
    return new DataTransfer().files;
  }

  const dataTransfer = new DataTransfer();

  for (const item of Array.from(dataTransferItems)) {
    const entry = item.webkitGetAsEntry();
    
    if (entry && entry.isDirectory) {
      await processDirectory(entry as FileSystemDirectoryEntry, dataTransfer);
    } else if (entry && entry.isFile) {
      await processFile(entry as FileSystemFileEntry, dataTransfer);
   }
 }

 return dataTransfer.files;
}

async function processDirectory(dirEntry: FileSystemDirectoryEntry, dataTransfer: DataTransfer): Promise<void> {
 const dirReader = dirEntry.createReader();
 const entries = await readEntries(dirReader);
 const fileEntries = entries.filter(entry => entry.isFile) as FileSystemFileEntry[];
 await Promise.all(fileEntries.map(entry => processFile(entry, dataTransfer)));
}

function readEntries(dirReader: FileSystemDirectoryReader): Promise<FileSystemEntry[]> {
 return new Promise((resolve) => {
   dirReader.readEntries((entries) => resolve(entries));
 });
}

function processFile(fileEntry: FileSystemFileEntry, dataTransfer: DataTransfer): Promise<void> {
 return new Promise((resolve) => {
   fileEntry.file((file) => {
     dataTransfer.items.add(file);
     resolve();
   });
 });
}

export async function handleFileDrop(
  event: DragEvent<HTMLDivElement>,
  dispatch: AppDispatch,
  isUploadEnabled: boolean,
  currentLanguage: ChatLanguage
) {
  event.preventDefault();
  if (!isUploadEnabled) {
    toast.error(currentLanguage.upload.disabled);
    return;
  }
  const files = await getFolderContents(event.dataTransfer.items)
  if (files) {
    dispatch(displayPreview(files));
  }
}

export function handleDragOver(event: DragEvent<HTMLDivElement>) {
  event.preventDefault();
}

export function normalizeFiles(files: string | any[] | undefined | null): string[] {
  if (!files) return []
  if (typeof files === 'string') return [files]
  if (Array.isArray(files) && files.every(file => typeof file === 'string')) return files
  return []
 }

export function createFileList(file: File): FileList {
  const dataTransfer = new DataTransfer();
  dataTransfer.items.add(file);
  return dataTransfer.files;
}

export function hasFiles(files?: string[]): boolean {
  return Array.isArray(files) && files.length > 0;
 }
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { setShowSettings } from '@states/slices/chatbotSlice';
import Settings from '@assets/images/settings.svg';
import CloseButton from '../CloseButton';
import './HeaderBar.scss';
import TransferButton from '../TransferChat/TransferButton';
import RestartButton from '../RestartButton';

function HeaderBar() {
  const dispatch = useDispatch();
  const {
    isSettingsEnabled,
    isRestartEnabled,
    isTransferEnabled,
    isFullScreenModeEnabled,
    isAddNewConversationEnabled,
  } = useSelector((state: RootState) => state.settings);
  const { isValidUser } = useSelector((state: RootState) => state.chatbot);

  return (
    <div className='header-bar'>
      {isSettingsEnabled && isValidUser && (
        <div
          className='settings-button'
          onClick={() => dispatch(setShowSettings(true))}
        >
          <img src={Settings} alt='Chatbot settings' />
        </div>
      )}
      {(isRestartEnabled || isAddNewConversationEnabled) && isValidUser && (
        <RestartButton />
      )}
      {isTransferEnabled && <TransferButton />}
      {!isFullScreenModeEnabled && <CloseButton />}
    </div>
  );
}

export default HeaderBar;

import { useDispatch, useSelector } from 'react-redux';
import useCurrentSkin from '@hooks/useCurrentSkin';
import { AppDispatch, RootState } from '@states/store';
import { useRef } from 'react';
import { displayPreview } from '@states/slices/filePreviewSlices';
import './FileUploadButton.scss';
import { isStreamingWaitingInProgress } from '@states/slices/chatbotSlice';

function FileUploadButton() {
  const { chatStatus, isUploadEnabled } = useSelector(
    (state: RootState) => state.chatbot
  );

  const isInputStreamingDisabled = useSelector(isStreamingWaitingInProgress);

  const dispatch: AppDispatch = useDispatch();
  const currentSkin = useCurrentSkin();

  const fileUploadInputRef = useRef<HTMLInputElement>(null);

  function handleSetAttachment(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (files) {
      dispatch(displayPreview(files));
    }
    if (fileUploadInputRef.current) {
      fileUploadInputRef.current.value = '';
    }
  }

  function handleKeyPress(event: React.KeyboardEvent<HTMLLabelElement>) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      if(chatStatus === 'FINISHED' ||isInputStreamingDisabled || !isUploadEnabled) return;
      fileUploadInputRef.current?.click();
    }
  }

  return (
    <label 
      htmlFor='file_upload' 
      className='file-attachment' 
      role="button" 
      aria-label='Upload File' 
      tabIndex={0}
      onKeyDown={handleKeyPress}
      >
      <input
        type='file'
        id='file_upload'
        ref={fileUploadInputRef}
        multiple
        disabled={
          chatStatus === 'FINISHED' ||
          isInputStreamingDisabled ||
          !isUploadEnabled
        }
        onChange={handleSetAttachment}
        style={{ display: 'none' }}
      />
      <img src={currentSkin.assets.fileAttachment} alt='Upload' />
    </label>
  );
}

export default FileUploadButton;

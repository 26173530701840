import { useSelector } from 'react-redux';
import { RootState } from '@states/store';
import { MessageItemProps } from './Message.type';
import {
  formatText,
  getFormattedTime,
  getGradientBackground,
} from '@utils/utils';
import { getFileSrc, hasFiles } from '@utils/files';
import LikeButtons from '@components/LikeButtons';
import MessageFile from './MessageFile';
import useCurrentSkin from '@hooks/useCurrentSkin';
import './MessagingItem.scss';
import { useEffect, useRef } from 'react';
import QuestionContent from '../SuggestedQuestions/QuestionContent';

function MessageItem({
  message,
  id,
  title,
  rating,
  isLatestMessage,
  questions,
  selected,
}: MessageItemProps) {
  const messageRef = useRef<HTMLDivElement>(null);

  const { color1, color2, botImage, agentImage, isLikeButtonsEnabled } =
    useSelector((state: RootState) => state.settings);
  const { currentSkinName } = useSelector((state: RootState) => state.skins);
  const { savedCookies, currentConversation } = useSelector(
    (state: RootState) => state.chatbot
  );

  const currentSkin = useCurrentSkin();

  useEffect(() => {
    if (isLatestMessage && messageRef.current) {
      messageRef.current.focus();
    }
  }, [isLatestMessage]);

  switch (message.pov) {
    case 'user':
      return (
        <div key={id} className='chatting-sub-container user'>
          <div
            className={hasFiles(message?.files) ? 'chatbot-reply-file' : 'chatbot-reply'}
            ref={messageRef}
            aria-label={`${message.pov} message. ${message.text}`}
            tabIndex={0}
            style={
              currentSkin.flags.chatbotReplyGradient
                ? { background: getGradientBackground({ color1, color2 }) }
                : {}
            }
          >
            <b
              className={currentSkin.css.chatbotArrow}
              style={{ borderColor: color1 }}
              aria-hidden='true'
            ></b>
            <div>{formatText(message.text)}</div>
            {Array.isArray(message?.files) && message?.files.length > 0 && <MessageFile files={message.files} /> }
            <span className='message-time' aria-label={message.time}>
              {getFormattedTime(message.time)}
            </span>
          </div>
        </div>
      );
    case 'bot':
      return (
        <>
          <div key={id} className='chatting-sub-container'>
            <div
              className='image-container'
              style={
                currentSkin.flags.chatbotLogoBorder
                  ? { borderColor: color1 }
                  : {}
              }
            >
              <img src={getFileSrc(botImage)} alt='' />
            </div>
            <div
              className='message'
              ref={messageRef}
              aria-label={`${message.pov} message. ${message.text}`}
              tabIndex={0}
            >
              {title && <h4 className='message-title'>{title}</h4>}
              <div className='message-text'>
                {formatText(message.text, savedCookies, currentConversation)}
              </div>
              {Array.isArray(message?.files) && message?.files.length > 0 && <MessageFile files={message.files} /> }
              <div className='timing-flex'>
                {isLikeButtonsEnabled ? (
                  <LikeButtons id={id} rating={rating} key={rating} />
                ) : (
                  <></>
                )}
                <span className='message-time'>
                  {getFormattedTime(message.time)}
                </span>
              </div>
            </div>
          </div>
          {currentSkinName === 'etoro' &&
            questions &&
            questions.length > 0 &&
            selected &&
            questions.includes(selected) && (
              <QuestionContent
                questions={questions}
                selected={selected}
                containerClassName={`suggested-questions-container feedback-question`}
              />
            )}
        </>
      );
    case 'system':
      return (
        <div className='chatting-sub-container' key={id}>
          <div
            className='message'
            style={{ fontStyle: 'italic', color: 'grey' }}
          >
            <p>{message.text}</p>
          </div>
        </div>
      );
    case 'agent':
      return (
        <div className='chatting-sub-container' key={id}>
          <div
            className='image-container'
            style={
              currentSkin.flags.chatbotLogoBorder ? { borderColor: color1 } : {}
            }
          >
            <img src={getFileSrc(agentImage)} alt='' />
          </div>
          <div className='message'>
            <p>{formatText(message.text)}</p>
            <span className='message-time'>
              {getFormattedTime(message.time)}
            </span>
          </div>
        </div>
      );
  }
}

export default MessageItem;

import { useEffect, useRef } from 'react';
import companyService from '@services/companyService';
import { useDispatch } from 'react-redux';
import {
  setIsConfigurationLoaded,
  updateSettings,
} from '@states/slices/settingsSlice';
import { setCurrentSkin } from '@/states/slices/skinsSlice';
import { MSW_ENABLED, TENANT } from '@utils/constants';
import { ConfigurationData } from '@/utils/types';
import { initFullStory } from '@/utils/fullStory';

function useCompanyConfig() {
  const dispatch = useDispatch();
  const isInitialMount = useRef(true);

  function updateCompanyConfig(configData: ConfigurationData) {
    const { skinName, pageTitle, favicon, metaDescription, fullStoryOrgId } =
      configData;

    dispatch(updateSettings(configData));

    skinName && dispatch(setCurrentSkin(skinName));

    if (pageTitle) {
      document.title = pageTitle;
    }
    if (favicon) {
      const link = document.querySelector(
        "link[rel~='icon']"
      ) as HTMLLinkElement;
      if (!link) {
        const newLink = document.createElement('link') as HTMLLinkElement;
        newLink.rel = 'icon';
        newLink.href = favicon;
        document.getElementsByTagName('head')[0].appendChild(newLink);
      } else {
        link.href = favicon;
      }
    }
    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        "meta[name='description']"
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.name = 'description';
        newMetaTag.content = metaDescription;
        document.getElementsByTagName('head')[0].appendChild(newMetaTag);
      }
    }
    if (fullStoryOrgId) {
      initFullStory(fullStoryOrgId);
    }
    dispatch(setIsConfigurationLoaded(true));
  }

  async function fetchCompanyConfigDev(): Promise<void> {
    const companyConfigDefault: ConfigurationData = {
      abTestPercentage: 100,
      botName: 'Example Bot',
      buttonText: 'Chat with us now!',
      variantSelection: 'default',
      productSelection: 'savings_account_english',
      languageSelection: 'english',
      skinName: 'default',
      pageTitle: 'Test Company Website',
      botImage: null,
      metaDescription: null,
      favicon: null,
      botBackground: null,
      footerLogo: null,
      color1: '',
      color2: '',
      botNameColor: null,
      botMessageColor: null,
      userMessageColor: null,
      isDefaultOpenEnabled: false,
      isDefaultOpenMobileEnabled: false,
      isDemoModeEnabled: true,
      isErrorDisplayEnabled: true,
      isFreemiumModeEnabled: true,
      isFullScreenModeEnabled: false,
      isPasswordEnabled: false,
      isPreviewEnabled: false,
      isRestartEnabled: true,
      isSettingsEnabled: true,
      isAuthedUsersOnlyEnabled: false,
      isLikeButtonsEnabled: true,
      isAutoFlowEnabled: true,
      isIdleEventEnabled: false,
      idleEventTimeoutInMinutes: 10,
      isOnlineStatusEnabled: true,
      isFileSendEnabled: true,
      isEmojiSendEnabled: true,
      inputFieldPlaceholder: '',
      isLikeFeedbackEnabled: false,
      isFeedbackSummaryEnabled: false,
      isMobileAgentEnabled: true,
      isTransferEnabled: true,
      isAddNewConversationEnabled: true,
      isFeedbackMessageEnabled: true,
      isFeedbackRatingEnabled: true,
      isInitEventEnabled: false,
      fullStoryOrgId: '',
      isStreamingEnabled: MSW_ENABLED ? false : true, // Disable streaming for testing
    };
    updateCompanyConfig(companyConfigDefault);
  }

  async function fetchCompanyConfigProd(): Promise<void> {
    const companyName = TENANT ? TENANT : location.hostname.split('.')[0];

    try {
      const configData: ConfigurationData =
        await companyService.getCompanyConfig(companyName);
      updateCompanyConfig(configData);
    } catch (error) {
      console.error('Error while fetching company config', error);
      return fetchCompanyConfigDev();
    }
  }

  const fetchCompanyConfig =
    import.meta.env.MODE === 'development'
      ? fetchCompanyConfigDev
      : fetchCompanyConfigProd;

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      fetchCompanyConfig();
    }
  }, []);
}
export default useCompanyConfig;
